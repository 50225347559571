import React from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import Delete from "./CancelOrder";
import Table from "@material-ui/core/Table";
import { MdEdit } from "react-icons/md";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
import { API_URL1 } from "../services/url";
import axios from "axios";
import { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import OpenLoadsButtons from "./OpenLoadsButtons";
import TablePagination from "@material-ui/core/TablePagination";
import DownloadPdf from "./DownloadPdf";
import TruckerForm from "./TruckerForm";

const Dispatcher = () => {
  const [recentOrder, setrecentOrder] = useState([]);
  const [sortedByOrderDate, setsortedByOrderDate] = useState(false);
  const [sortedByquantity, setSortedByquantity] = useState(false);
  const [sortedBycolor, setSortedByColor] = useState(false);
  const [sortedByDeliveryDate, setSortedByDeliveryDate] = useState(false);
  const [sortedByJobName, setsortedByJobName] = useState(false);
  const [sortedByPO, setsortedByPO] = useState(false);
  const [sortedByShipedqty, setsortedByShipedqty] = useState(false);
  const [sortedByRemainquantity, setsortedByRemainquantity] = useState(false);
  const [sortedBySize, setsortedBySize] = useState(false);
  const [sortedByTrucker, setsortedByTrucker] = useState(false);
  const [record, setRecord] = useState(false);
  const [page, setPage] = useState(0);
  const [ID, SetID] = useState(false);
  const [addresssort, SetAddress] = useState(false);
  const [Cross_Street, SetCross_Street] = useState(false);
  const [Shid, SetShid] = useState(false);
  const [lot, SetLot] = useState(false);
  const [lotaddress, SetLotAdress] = useState(false);
  const [personName, SetPersonName] = useState(false);
  const [contractName, SetContractName] = useState(false);
  const [rowsPerPage, setrowsPerPage] = useState(100);
  const [nonFilteredData, setnonFilteredData] = useState([]);
  const [query, SetQuery] = useState(false);

  useEffect(() => {
    const FetchData = async () => {
      try {
        const response = await axios.get(
          `${API_URL1}trucker/dispatch/truckRequest`,
          {
            headers: {
              "x-access-token": localStorage.getItem("TruckertokenKey"),
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        let Order = [];
        console.log(response, "Thisi s Datata")
        if (response?.data?.data) {

          Order.push(response?.data?.data);
          setrecentOrder(response?.data?.data);
          setnonFilteredData(response?.data?.data);
          setRecord(true);
        } else {
          setrecentOrder([]);
          setRecord(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    FetchData();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const sortOrderId = () => {
    if (ID) {
      setrecentOrder(recentOrder.reverse());

      SetID(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.po_number < b.po_number) {
          return -1;
        }
        if (a.po_number > b.po_number) {
          return 1;
        }
        return 0;
      });
      setrecentOrder(data);
      SetID(true);
    }
  };
  const sortShId = () => {
    if (Shid) {
      setrecentOrder(recentOrder.reverse());
      SetShid(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.pk < b.pk) {
          return -1;
        }
        if (a.pk > b.pk) {
          return 1;
        }
        return 0;
      });
      setrecentOrder(data);
      SetShid(true);
    }
  };
  const sortOrderDate = () => {
    if (sortedByOrderDate) {
      setrecentOrder(recentOrder.reverse());
      setsortedByOrderDate(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.currentDate < b.currentDate) {
          return -1;
        }
        if (a.currentDate > b.currentDate) {
          return 1;
        }
        return 0;
      });

      setrecentOrder(data);
      setsortedByOrderDate(true);
    }
  };
  const sortDeliveryDate = () => {
    if (sortedByDeliveryDate) {
      setrecentOrder(recentOrder.reverse());
      setSortedByDeliveryDate(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.deliver_date < b.deliver_date) {
          return -1;
        }
        if (a.deliver_date > b.deliver_date) {
          return 1;
        }
        return 0;
      });

      setrecentOrder(data);
      setSortedByDeliveryDate(true);
    }
  };
  const sortJobName = () => {
    if (sortedByJobName) {
      setrecentOrder(recentOrder.reverse());
      setsortedByJobName(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setrecentOrder(data);
      setsortedByJobName(true);
    }
  };

  const sortAddress = () => {
    if (addresssort) {
      setrecentOrder(recentOrder.reverse());
      SetAddress(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.address_line_1 < b.address_line_1) {
          return -1;
        }
        if (a.address_line_1 > b.address_line_1) {
          return 1;
        }
        return 0;
      });

      setrecentOrder(data);
      SetAddress(true);
    }
  };
  const sortCross_Street = () => {
    if (Cross_Street) {
      setrecentOrder(recentOrder.reverse());
      SetCross_Street(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.cross_streets < b.cross_streets) {
          return -1;
        }
        if (a.cross_streets > b.cross_streets) {
          return 1;
        }
        return 0;
      });

      setrecentOrder(data);
      SetCross_Street(true);
    }
  };
  const handleChangePO = () => {
    if (sortedByPO) {
      setrecentOrder(recentOrder.reverse());
      setsortedByPO(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.number < b.number) {
          return -1;
        }
        if (a.number > b.number) {
          return 1;
        }
        return 0;
      });

      setrecentOrder(data);
      setsortedByPO(true);
    }
  };

  const sortLot = () => {
    if (lot) {
      setrecentOrder(recentOrder.reverse());
      SetCross_Street(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.lot < b.lot) {
          return -1;
        }
        if (a.lot > b.lot) {
          return 1;
        }
        return 0;
      });

      SetLot(data);
      SetCross_Street(true);
    }
  };

  const sortLotAddress = () => {
    if (lotaddress) {
      setrecentOrder(recentOrder.reverse());
      SetLotAdress(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.lot_address < b.lot_address) {
          return -1;
        }
        if (a.lot_address > b.lot_address) {
          return 1;
        }
        return 0;
      });

      setrecentOrder(data);
      SetLotAdress(true);
    }
  };
  const sortPersonName = () => {
    if (personName) {
      setrecentOrder(recentOrder.reverse());
      SetPersonName(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.site_person_name < b.site_person_name) {
          return -1;
        }
        if (a.site_person_name > b.site_person_name) {
          return 1;
        }
        return 0;
      });

      setrecentOrder(data);
      SetPersonName(true);
    }
  };
  const sortContractName = () => {
    if (contractName) {
      setrecentOrder(recentOrder.reverse());
      SetContractName(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.site_person_contact < b.site_person_contact) {
          return -1;
        }
        if (a.site_person_contact > b.site_person_contact) {
          return 1;
        }
        return 0;
      });

      setrecentOrder(data);
      SetContractName(true);
    }
  };

  const handleChangeColor = () => {
    if (sortedBycolor) {
      setrecentOrder(recentOrder.reverse());
      setSortedByColor(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.color_name < b.color_name) {
          return -1;
        }
        if (a.color_name > b.color_name) {
          return 1;
        }
        return 0;
      });

      setrecentOrder(data);
      setSortedByColor(true);
    }
  };
  const handleChangeSize = () => {
    if (sortedBySize) {
      setrecentOrder(recentOrder.reverse());
      setsortedBySize(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.size < b.size) {
          return -1;
        }
        if (a.size > b.size) {
          return 1;
        }
        return 0;
      });

      setrecentOrder(data);
      setsortedBySize(true);
    }
  };
  const handleChangeOriginalQty = () => {
    if (sortedByquantity) {
      setrecentOrder(recentOrder.reverse());
      setSortedByquantity(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.total_job_tonnage < b.total_job_tonnage) {
          return -1;
        }
        if (a.total_job_tonnage > b.total_job_tonnage) {
          return 1;
        }
        return 0;
      });

      setrecentOrder(data);
      setSortedByquantity(true);
    }
  };

  const handleChangeShippedQty = () => {
    if (sortedByShipedqty) {
      setrecentOrder(recentOrder.reverse());
      setsortedByShipedqty(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.truck_quantity < b.truck_quantity) {
          return -1;
        }
        if (a.truck_quantity > b.truck_quantity) {
          return 1;
        }
        return 0;
      });

      setrecentOrder(data);
      setsortedByShipedqty(true);
    }
  };
  const handleChangeRemainingQty = () => {
    if (sortedByRemainquantity) {
      setrecentOrder(recentOrder.reverse());
      setsortedByRemainquantity(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.left_quantity < b.left_quantity) {
          return -1;
        }
        if (a.left_quantity > b.left_quantity) {
          return 1;
        }
        return 0;
      });

      setrecentOrder(data);
      setsortedByRemainquantity(true);
    }
  };
  const handleChangeQuarry = () => {
    if (query) {
      setrecentOrder(recentOrder.reverse());
      SetQuery(false);
    } else {
      var data = recentOrder;

      data.sort((a, b) => {
        if (a.quarry_site_name < b.quarry_site_name) {
          return -1;
        }
        if (a.quarry_site_name > b.quarry_site_name) {
          return 1;
        }
        return 0;
      });

      setrecentOrder(data);
      SetQuery(true);
    }
  };
  const handleChangeTrucker = () => {
    if (sortedByTrucker) {
      setrecentOrder(recentOrder.reverse());
      setsortedByTrucker(false);
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.truck_type < b.truck_type) {
          return -1;
        }
        if (a.truck_type > b.truck_type) {
          return 1;
        }
        return 0;
      });

      setrecentOrder(data);
      setsortedByTrucker(true);
    }
  };

  const handleSearchChange = (e) => {
    let searchQuery = e.target.value;
    if (searchQuery !== "") {
      const Sdata = nonFilteredData;
      var data = Sdata.filter((data) => {
        return (
          (data.order_id &&
            String(data.order_id).toLowerCase().includes(searchQuery)) ||
          (data.po_number &&
            String(data.po_number).toLowerCase().includes(searchQuery)) ||
          (data.pk && String(data.pk).toLowerCase().includes(searchQuery)) ||
          (data.lot && data.lot.toLowerCase().includes(searchQuery)) ||
          (data.lot_address &&
            data.lot_address.toLowerCase().includes(searchQuery)) ||
          (data.quantity &&
            String(data.quantity).toLowerCase().includes(searchQuery)) ||
          (data.color && data.color.toLowerCase().includes(searchQuery)) ||
          (data.name && data.name.toLowerCase().includes(searchQuery)) ||
          (data.color && data.color.toLowerCase().includes(searchQuery)) ||
          (data.quarry_site_name &&
            data.quarry_site_name.toLowerCase().includes(searchQuery)) ||
          (data.truck_type &&
            data.truck_type.toLowerCase().includes(searchQuery)) ||
          (data.cross_streets &&
            data.cross_streets.toLowerCase().includes(searchQuery)) ||
          (data.size && data.size.toLowerCase().includes(searchQuery))
        );
      });
      setrecentOrder(data);
    } else {
      setrecentOrder(nonFilteredData);
    }
  };

  return (
    <Grid container spacing={2}>
      {" "}
      {!record ? <LinearProgress /> : ""}
      <Grid xs={12} sm={12} mb={2}>
        <OpenLoadsButtons />
        <input
          style={{
            width: "162px",
            marginTop: "28px",
            marginLeft: "15px",
            height: "30px",
          }}
          type="text"
          id="searcheverything"
          className="form-control"
          onChange={handleSearchChange}
          placeholder="Search"
        />
      </Grid>
      {!record && recentOrder.length === 0 ? (
        <div style={{ marginLeft: "3%" }}>Loading.........</div>
      ) : recentOrder.length === 0 && record ? (
        <div style={{ marginLeft: "3%" }}>No Record Available</div>
      ) : (
        <Paper style={{ width: "110%", marginLeft: "5%" }}>
          <TableContainer
            sx={{
              background: "#F3F3F3",
              boxShadow: "5px 5px 6px #434344",
              borderRadius: "10px",
            }}
            style={{ height: "65vh", overflow: "scroll" }}
          >
            <Table
              stickyHeader
              aria-label="simple table"
              style={{ color: "#000" }}
            >
              <TableHead style={{ backgroundColor: "#000000" }}>
                <TableRow>
                  <TableCell
                    style={{
                      minWidth: 100,
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                  >
                    Action
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 100,
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ cursor: "pointer", marginRight: "2px" }}
                      className="fa fa-sort"
                      aria-hidden="true"
                      onClick={sortOrderId}
                    />
                    Pick Up
                  </TableCell>
                  {/* <TableCell
                    style={{
                      minWidth: 150,
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ cursor: "pointer", marginRight: "2px" }}
                      className="fa fa-sort"
                      aria-hidden="true"
                      onClick={sortShId}
                    />
                    Scheduled Id
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 150,
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ cursor: "pointer", marginRight: "2px" }}
                      className="fa fa-sort"
                      aria-hidden="true"
                      onClick={sortOrderDate}
                    />
                    Order Date
                  </TableCell> */}
                  <TableCell
                    style={{
                      minWidth: 150,
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ cursor: "pointer", marginRight: "2px" }}
                      className="fa fa-sort"
                      aria-hidden="true"
                      onClick={sortDeliveryDate}
                    />
                    Delivery Date
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 150,
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                    align="center"
                  >
                    <i
                      onClick={sortJobName}
                      style={{ cursor: "pointer", marginRight: "2px" }}
                      className="fa fa-sort"
                      aria-hidden="true"
                    />
                    Job Name
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 200,
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                    align="center"
                  >
                    <i
                      onClick={sortAddress}
                      style={{ cursor: "pointer", marginRight: "2px" }}
                      className="fa fa-sort"
                      aria-hidden="true"
                    />
                    Address
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 150,
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                  >
                    <i
                      onClick={sortCross_Street}
                      style={{ cursor: "pointer", marginRight: "2px" }}
                      className="fa fa-sort"
                      aria-hidden="true"
                    />
                    Cross Street
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 100,
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                  >
                    <i
                      onClick={handleChangePO}
                      style={{ cursor: "pointer", marginRight: "2px" }}
                      className="fa fa-sort"
                      aria-hidden="true"
                    />
                    PO
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 150,
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                  >
                    <i
                      onClick={sortPersonName}
                      style={{ cursor: "pointer", marginRight: "2px" }}
                      className="fa fa-sort"
                      aria-hidden="true"
                    />
                    Site Person Name
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 150,
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                  >
                    <i
                      onClick={sortContractName}
                      style={{ cursor: "pointer", marginRight: "2px" }}
                      className="fa fa-sort"
                      aria-hidden="true"
                    />
                    Site Person Contact
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 100,
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                  >
                    <i
                      onClick={handleChangeColor}
                      style={{ cursor: "pointer", marginRight: "2px" }}
                      className="fa fa-sort"
                      aria-hidden="true"
                    />
                    Color
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 100,
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                  >
                    <i
                      onClick={handleChangeSize}
                      style={{ cursor: "pointer", marginRight: "2px" }}
                      className="fa fa-sort"
                      aria-hidden="true"
                    />
                    Size
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 150,
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                  >
                    <i
                      onClick={handleChangeOriginalQty}
                      style={{ cursor: "pointer", marginRight: "2px" }}
                      className="fa fa-sort"
                      aria-hidden="true"
                    />
                    Quantity
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 170,
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                  >
                    <i
                      onClick={handleChangeQuarry}
                      style={{ cursor: "pointer", marginRight: "2px" }}
                      className="fa fa-sort"
                      aria-hidden="true"
                    />
                    P/U Quarry
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 190,
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                  >
                    <i
                      onClick={handleChangeTrucker}
                      style={{ cursor: "pointer", marginRight: "2px" }}
                      className="fa fa-sort"
                      aria-hidden="true"
                    />
                    Trucker/Trailer Req
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody style={{ backgroundColor: "#E4E4E4" }}>
                {recentOrder &&
                  recentOrder
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, key) => (
                      <TableRow
                        key={key}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell style={{ minWidth: 160 }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              width: 2,
                            }}
                          >
                            <Link
                              to={`/dispatched_order/${item.pick_up}`}
                            >
                              <MdEdit
                                style={{
                                  color: "#0075ff",
                                  fontSize: "17px",
                                  marginRight: "12px",
                                }}
                              />
                            </Link>
                            {/* <Delete
                              truck_request_id={item.truck_request_id}
                              job_detail_id={item.pk}
                              po_number={item.po_number}
                            />
                            <DownloadPdf pdf={item.upload_map} />
                            <TruckerForm
                              truck_request_id={item.truck_request_id}
                            /> */}
                          </Box>
                        </TableCell>
                        <TableCell style={{ minWidth: 150 }}>
                          {item.pick_up}
                        </TableCell>
                        {/* <TableCell style={{ minWidth: 150 }}>
                          {item.pk ? item.pk : "N/A"}
                        </TableCell>
                        <TableCell style={{ minWidth: 150 }}>
                          {item.currentDate
                            ? moment(item.currentDate).format("ll")
                            : "N/A"}
                        </TableCell> */}
                        <TableCell style={{ minWidth: 150 }}>
                          {item.dsr_dt
                            ? moment(
                              new Date(item.dsr_dt).setHours(
                                new Date(item.dsr_dt).getHours() + 12
                              )
                            ).format("ll")
                            : "N/A"}
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 150 }}>
                          {item.project_name ? item.project_name : "N/A"}
                        </TableCell>{" "}
                        <TableCell align="center" style={{ minWidth: 200 }}>
                          {item.addresses
                            ? item.addresses
                            : "N/A"}
                        </TableCell>{" "}
                        <TableCell style={{ minWidth: 150 }}>
                          {item.cross_streets
                            ? "N/A"
                            : item.cross_streets}
                        </TableCell>
                        <TableCell style={{ minWidth: 150 }}>
                          {!item.po_no ? 0 : item.po_no}
                        </TableCell>{" "}
                        <TableCell style={{ minWidth: 150 }}>
                          {item.contractor_name
                            ? item.contractor_name
                            : "N/A"}
                        </TableCell>
                        <TableCell style={{ minWidth: 150 }}>
                          {item.contacts
                            ? item.contacts
                            : "N/A"}
                        </TableCell>
                        <TableCell style={{ minWidth: 150 }}>
                          {item.item_name ? item.item_name.split(" ")[0] : "N/A"}
                        </TableCell>{" "}
                        <TableCell style={{ minWidth: 150 }}>
                          {item.item_name ? item.item_name.split(" ").slice(1).join(" ") : "N/A"}
                        </TableCell>
                        <TableCell style={{ minWidth: 150 }}>
                          {item.amt_dispatched
                            ? parseFloat(item.amt_dispatched).toFixed(2)
                            : "N/A"}
                        </TableCell>{" "}
                        <TableCell style={{ minWidth: 150 }}>
                          {item.supplier ?? ""}
                        </TableCell>{" "}
                        <TableCell style={{ minWidth: 150 }}>
                          {item.truck_type}
                        </TableCell>{" "}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={recentOrder && recentOrder.length}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            style={{ fontSize: 16 }}
          />
        </Paper>
      )}
    </Grid>
  );
};

export default Dispatcher;
