import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import { API_URL1 } from "../services/url";
import { useAlert } from "react-alert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";

const AddDriver = () => {
  const navigate = useNavigate();
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [phone_no, setphn] = useState("");
  const [show, setShow] = useState(false);
  const [licenseNo, setLicenseNo] = useState("");
  const [truck, setTruck] = useState([]);
  const [truckId, setTruckId] = useState("");
  const alert = useAlert();
  const [btn_status, setbtn_Status] = useState("unclick");
  const [error, setfeildeErr] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_URL1}trucker/driver/truckToAssigned`, {
        headers: {
          "x-access-token": localStorage.getItem("TruckertokenKey"),
        },
      })
      .then((response) => {
        if (response.data.Trucks) {
          setTruck([...response.data.Trucks]);
        }
      });
  }, []);

  const handleAdd = async (e) => {
    e.preventDefault();

    var phone = phone_no;
    if (phone.charAt(0) === "9" && phone.charAt(1) === "1") {
      var mob = "+91 ";

      for (let i = 2; i < phone.length; i++) {
        if (
          phone[i] !== " " &&
          phone[i] !== "(" &&
          phone[i] !== ")" &&
          phone[i] !== "-"
        )
          mob = mob + phone[i];
      }
      phone = mob;
    }
    if (phone.charAt(0) === "1") {
      var mob = "+1 ";

      for (let i = 1; i < phone.length; i++) {
        if (
          phone[i] !== " " &&
          phone[i] !== "(" &&
          phone[i] !== ")" &&
          phone[i] !== "-"
        )
          mob = mob + phone[i];
      }

      phone = mob;
    }

    const driver = {
      name: firstname + " " + lastname,
      phone_no: phone,
      license_no: licenseNo,
      truck_company_id: Number(localStorage.getItem("adminid")),
      truck_id: Number(truckId),
      company_name: localStorage.getItem("company_name"),
      trucker_email: localStorage.getItem("Temail"),
    };

    if (
      firstname != "" &&
      lastname !== "" &&
      truckId !== "" &&
      phone_no !== ""
    ) {
      setbtn_Status("click");
      try {
        const response = await axios.post(`${API_URL1}trucker/driver`, driver, {
          headers: {
            "x-access-token": localStorage.getItem("TruckertokenKey"),
            "content-type": "application/json",
          },
        });

        if (response.data.success) {
          setShow(false);
          alert.success(response.data.message, {
            onClose: () => {
              window.location = "/viewdriver";
            },
          });
        } else {
          setShow(false);
          alert.error(response.data.message, {
            onClose: () => {
              window.location = "/viewdriver";
            },
          });
        }
      } catch (error) {
        console.log(error);
        setShow(false);
        alert.error("something went wrong", {
          onClose: () => {
            window.location = "/viewdriver";
          },
        });
      }
    } else {
      setfeildeErr(true);
    }
  };
  return (
    <>
    <TableContainer
      component={Paper}
      style={{
        marginTop: "4%",
        marginRight: "2%",
        marginLeft: "15%",
        boxShadow: "none",
      }}
      sx={{
        // background: "#F3F3F3",
        // height: "84vh",
        borderRadius: "10px",
        width: 850,
      }}
      // align="center"
    >
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/viewdriver')}
        style={{
          // float: "left",
                // marginTop: "3%",
                marginLeft: "",
                width: "132px",
                color: "white",
                backgroundColor: "#114C65",
                borderRadius: "25px",
        }}
      >
        Back
      </Button>
    </TableContainer>
    <TableContainer
      component={Paper}
      style={{
        marginTop: "4%",
        marginRight: "2%",
        marginLeft: "15%",
        marginBottom: "100px",
        boxShadow: "5px 5px 6px #434344",
      }}
      sx={{
        background: "#F3F3F3",
        width: 850,
        height: 530,
        borderRadius: "10px",
      }}
      align="center"
    >
      <Typography
        fontWeight="bold"
        variant="h6"
        color="initial"
        fontSize="22px"
        paddingBottom="2%"
        paddingTop="2%"
      >
        Add Driver
      </Typography>
      {error ? <p style={{ color: "red" }}>* All Fields Required</p> : null}

      <form onSubmit={handleAdd}>
        <Grid
          container
          spacing={3}
          sx={{
            padding: "0 5% 0 5%",
          }}
        >
          <Grid item xs={8} sm={4}>
            {" "}
            <Typography
              sx={{ mb: 1 }}
              align="left"
              fontSize="18px"
              fontWeight="bold"
            >
              Name:{" "}
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            <TextField
              sx={{
                background: "#FFFFFF",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",

                border: "none",
              }}
              size="small"
              border="none"
              required
              type="string"
              value={firstname}
              onChange={(e) => {
                setfirstname(e.target.value);
                setfeildeErr(false);
              }}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={8} sm={4}></Grid>
          <Grid item xs={8} sm={4}>
            <Typography
              sx={{ mb: 1 }}
              fontSize="18px"
              fontWeight="bold"
              align="left"
            >
              Last Name:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            {" "}
            <TextField
              sx={{
                background: "#FFFFFF",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
              type="string"
              value={lastname}
              onChange={(e) => {
                setlastname(e.target.value);
                setfeildeErr(false);
              }}
              required
              fullWidth
              size="small"
              border="none"
            ></TextField>
          </Grid>
          <Grid item xs={8} sm={4}></Grid>
          <Grid item xs={8} sm={6}>
            <Typography
              sx={{ mb: 1 }}
              fontSize="18px"
              fontWeight="bold"
              align="left"
            >
              Contact Number:
            </Typography>
          </Grid>
          <Grid item xs={3} sm={2} paddingRight="6.4rem">
            <PhoneInput
              required
              country={"us"}
              value={phone_no}
              inputStyle={{ width: "240px" }}
              style={{
                marginLeft: "-275px",
                width: "20px",
                background: "#FFFFFF",
              }}
              onChange={(phone_no) => {
                setphn(phone_no);
                setfeildeErr(false);
              }}
            />
          </Grid>
          <Grid item xs={8} sm={4}></Grid>
          <Grid item xs={8} sm={4}>
            <Typography
              sx={{ mb: 1 }}
              align="left"
              fontSize="18px"
              fontWeight="bold"
            >
              Default Truck:{" "}
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            <select
              required
              style={{
                height: "41px",
                width: "240px!important",
                background: "#FFFFFF",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",

                border: "none",
              }}
              className="form-control  mr-1"
              value={truckId}
              onChange={(e) => {
                setTruckId(e.target.value);
                setfeildeErr(false);
              }}
            >
              <option> Select Available Truck</option>
              {truck &&
                truck.map((item, key) => {
                  return (
                    <option key={key} value={item.truck_id}>
                      {item.brand_model}
                    </option>
                  );
                })}
            </select>
            <p style={{textAlign: "left", fontSize: "12px", color: "#666", marginTop: "5px"}}>
            Only shows trucks that are not assigned to any driver
            </p>
            <p style={{textAlign: "left", fontSize: "12px", color: "#666", marginTop: "5px"}}>
            By entering the phone number, the driver agrees to receive text messages from RockPros.
            </p>
          </Grid>
          <Grid item xs={8} sm={4}></Grid> <Grid item xs={8} sm={3}></Grid>{" "}
          <Grid item xs={8} sm={6}>
            <Button
              variant="contained"
              type="submit"
              onClick={handleAdd}
              style={{
                padding: "5px",
                marginTop: "3%",
                paddingLeft: "50px",
                paddingRight: "50px",
                backgroundColor: "#000",
                borderRadius: "20px",
              }}
              disabled={btn_status === "click"}
            >
              <Typography color="white">Add </Typography>
            </Button>
          </Grid>{" "}
          <Grid item xs={8} sm={3}></Grid>
        </Grid>
      </form>
    </TableContainer>
    </>
  );
};

export default AddDriver;
